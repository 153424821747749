// extracted by mini-css-extract-plugin
export var contentRoot = "index-module--content-root--1gWhd";
export var spacer = "index-module--spacer--3SzEZ";
export var top = "index-module--top--1Mb1y";
export var bottom = "index-module--bottom--1NwZe";
export var appBar = "index-module--app-bar--2tp-G";
export var logo = "index-module--logo--3sd7s";
export var bountyhunt = "index-module--bountyhunt--24cyg";
export var textContainer = "index-module--text-container--18Vex";
export var mainText = "index-module--main-text--2gEjV";
export var secondaryText = "index-module--secondary-text--eV3gL";
export var image = "index-module--image--2THIp";
export var sponsorkit = "index-module--sponsorkit--10cnb";
export var footer = "index-module--footer--1Lu6x";
export var container = "index-module--container--k7ALA";
export var sections = "index-module--sections--9C-ZE";
export var section = "index-module--section--Dyjl7";
export var title = "index-module--title--1XGBb";
export var links = "index-module--links--2-l3q";
export var link = "index-module--link--1Un0D";